import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {i18nextPlugin} from 'translation-check'

import translationBG from '../translation/bg/translation.json'
import translationEN from '../translation/en/translation.json'
import {getLanguageCode} from './LanguageUtils'

const languageDetectorOptions = {
    // order and from where user language should be detected
    order: [
        'localStorage',
        'navigator',
        'sessionStorage',
        'cookie',
        'querystring',
        'htmlTag',
        'path',
        'subdomain',
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    cookieOptions: {path: '/', sameSite: 'strict'},

    // optional conversion function to use to modify the detected language code
    convertDetectedLanguage: 'Iso15897',
    convertDetectedLanguage: lng => lng.replace('-', '_'),
}

const resources = {
    gb: {
        translation: translationEN,
    },
    en: {
        translation: translationEN,
    },
    bg: {
        translation: translationBG,
    },
}

function getI18nOption() {
    let initialOptions = {
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        detection: languageDetectorOptions,
        resources,

        fallbackLng: 'gb',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        keySeparator: false, // we do not use keys in form messages.welcome
    }

    if (!localStorage.hasOwnProperty('i18nextLng')) {
        return initialOptions
    }

    return {
        ...initialOptions,
        lng: getLanguageCode(),
    }
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(i18nextPlugin)
    // .use(LanguageDetector)
    .init(getI18nOption())

export default i18n
