import React, {useEffect, useMemo, useRef, useState} from 'react'
import ReactFlagsSelect from 'react-flags-select'

import {
    countryToLanguage,
    getLanguageCode,
    languageToCountry,
    setLanguageCode,
} from '../i18n/LanguageUtils'
import {InputText} from 'primereact/inputtext'
import {Divider} from 'primereact/divider'
import {Button} from 'primereact/button'
import {Toast} from 'primereact/toast'
import {Card} from 'primereact/card'
import UserIcon from '../assets/images/undraw_online_dating_re_hu03.svg'
import {Dialog} from 'primereact/dialog'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import {validEmail} from './utils/Utils'
import ReCAPTCHA from 'react-google-recaptcha'

const TutorialVideos = () => {
    const [stage, setStage] = useState(
        localStorage.getItem('stage')
            ? localStorage.getItem('stage')
            : 'register',
    )
    const {i18n} = useTranslation()
    const [dialog, setDialog] = useState(false)
    const [url, setUrl] = useState('')
    const [data, setData] = useState({})
    const toast = useRef(null)
    const captchaRef = useRef(null)
    const [disableButton, setDisableButton] = useState(true)
    const {t} = useTranslation('translation')

    const currentLanguage = i18next.language

    useEffect(() => {
        i18n.changeLanguage(getLanguageCode())
    }, [i18n])

    const videoList = useMemo(() => {
        let list = [
            // {
            //   title: t("Super Admin"),
            //   sub: `TaVIE ${t("Web Console Training for Super Admin")}`,
            //   icon: AdminIcon.src,
            //   url: "https://www.youtube.com/embed/ztHKPkaAIcI?si=h4FBlmXh3lo7XQnx",
            // },
            {
                title: t('User'),
                sub: `TaVIE ${t('Web Console Training for User')}`,
                icon: UserIcon.src,
                url: 'https://www.youtube.com/embed/5YO7qgNcwhc?si=cgCZVJMgynAdZQuz',
                className: ' mt-3',
            },
        ]

        if (currentLanguage === 'bg') {
            list = [
                // {
                //   title: t("Super Admin"),
                //   sub: `TaVIE ${t("Web Console Training for Super Admin")}`,
                //   icon: AdminIcon.src,
                //   url: "https://www.youtube.com/embed/S7XyuzE2TEw?si=vFrSAQ2NdfQ7ZPgm",
                // },
                {
                    title: t('User'),
                    sub: `TaVIE ${t('Web Console Training for User')}`,
                    icon: UserIcon.src,
                    url: 'https://www.youtube.com/embed/S7XyuzE2TEw?si=vFrSAQ2NdfQ7ZPgm',
                    className: ' mt-3',
                },
            ]
        }
        return list
    }, [t, currentLanguage])

    const handleOnChange = (key, value) => {
        setData({...data, [key]: value})
    }

    const showNotification = (severity, summary, detail) => {
        toast.current.show(
            {
                severity: severity,
                summary: t(summary),
                detail: t(detail),
            },
            3000,
        )
    }

    const handleSubmitRegistration = async () => {
        if (!data?.name) {
            showNotification('error', 'Error', 'Name is required !!')

            return
        }
        if (!data?.email) {
            showNotification('error', 'Error', 'Email is required !!')
            return
        }

        if (!validEmail(data?.email)) {
            showNotification('error', 'Error', 'Email is invalid !!')
            return
        }

        let toPostData = {
            email: data?.email,
            fullName: data?.name,
            language: i18n.language,
        }

        await fetch(
            'https://gateway-dev.thinkvitalshh.com/mail/api/mail/sendMailForTaVIEResource',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(toPostData),
            },
        )
            .then(response => {
                if (data?.name && data?.email) {
                    localStorage.setItem('stage', 'accessCode')
                }

                setStage('accessCode')
                setData({
                    name: '',
                    email: '',
                    accessCode: '',
                })

                setDisableButton(true)
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    const handleSubmitAccessCode = () => {
        if (!data?.accessCode) {
            showNotification('error', 'Error', 'Access Code is required !!')
            return
        }

        if (data?.accessCode !== 'TaVIE-TVHH-2024') {
            showNotification('error', 'Error', 'Access Code is invalid !!')
            return
        }

        if (data?.accessCode) {
            localStorage.setItem('stage', 'video')
        }

        setData({
            name: '',
            email: '',
            accessCode: '',
        })

        setStage('video')
        setDisableButton(true)
    }

    const [shown, setShown] = useState(true)

    useEffect(() => {
        if (!shown) {
            setShown(true)
        }
    }, [shown])

    return (
        <div className="h-screen bg-white ">
            <div className="flex justify-content-end w-full custom-flags-select p-4">
                <ReactFlagsSelect
                    showOptionLabel={true}
                    showSelectedLabel={true}
                    placeholder={t('Select a language')}
                    countries={['US', 'GB', 'BG']}
                    customLabels={{
                        US: 'US - English',
                        GB: 'UK - English',
                        BG: 'Bulgarian',
                    }}
                    selected={languageToCountry(getLanguageCode())}
                    onSelect={code => {
                        let convertCode = countryToLanguage(code)
                        setLanguageCode(convertCode)
                        i18n.changeLanguage(convertCode)
                        setShown(false)
                    }}
                />
            </div>
            {console.log(shown)}
            {stage === 'register' && (
                <div className="h-full p-4 flex align-items-center">
                    <div className="flex justify-content-center w-full">
                        <div className="flex flex-column gap-3 col-10 md:col-6 xl:col-3 bg-silver p-5 border-round-xl">
                            <h1 className=" main-text-color m-0 ">
                                {t('Registration Form')}{' '}
                            </h1>
                            <p className="m-0 p-0 text-500 text-sm">
                                {t(
                                    'Please fill in the form before accessing our resources',
                                )}
                            </p>
                            <div className="flex flex-column gap-1">
                                <h5 className=" m-0 p-0 text-sm text-500">
                                    {t('Name')}{' '}
                                </h5>
                                <InputText
                                    className="border-round-sm"
                                    value={data?.name ?? ''}
                                    onChange={e =>
                                        handleOnChange('name', e.target.value)
                                    }
                                />
                            </div>
                            <div className="flex flex-column gap-1">
                                <h5 className=" m-0 p-0 text-sm text-500">
                                    {t('Email')}{' '}
                                </h5>
                                <InputText
                                    className="border-round-sm"
                                    keyfilter="email"
                                    value={data?.email ?? ''}
                                    onChange={e =>
                                        handleOnChange('email', e.target.value)
                                    }
                                />
                            </div>
                            <div className="col-12 px-0">
                                {' '}
                                {/* {!!shown && ( */}
                                    <ReCAPTCHA
                                        id="recaptcha"
                                        ref={captchaRef}
                                        hl={
                                           "en"
                                        }
                                        size="normal"
                                        sitekey="6LcyqHckAAAAALSdq50sK4puawkLWajIgAMiYyVt"
                                        onChange={() => {
                                            setDisableButton(false)
                                        }}
                                        onErrored={() => {
                                            if (
                                                !!window?.grecaptcha &&
                                                !!window?.grecaptcha?.reset
                                            ) {
                                                window?.grecaptcha?.reset()
                                            }

                                            setDisableButton(true)
                                        }}
                                        onExpired={() => {
                                            if (
                                                !!window?.grecaptcha &&
                                                !!window?.grecaptcha?.reset
                                            ) {
                                                window?.grecaptcha?.reset()
                                            }

                                            setDisableButton(true)
                                        }}
                                    />
                                {/* )} */}
                            </div>

                            <Divider className="m-1" />
                            <Button
                                disabled={!!disableButton}
                                className="border-round-sm primary-button h-3rem"
                                icon="pi pi-send"
                                label={t('SUBMIT')}
                                onClick={() => handleSubmitRegistration()}
                            />
                        </div>
                    </div>
                </div>
            )}

            {stage === 'accessCode' && (
                <div className="h-full p-4 flex align-items-center">
                    <div className="flex justify-content-center w-full">
                        <div className="flex flex-column gap-3 col-10 md:col-6 xl:col-3 bg-silver p-5 border-round-xl">
                            <h1 className=" main-text-color m-0 ">
                                {t('Access Code')}{' '}
                            </h1>
                            <p className="m-0 p-0 text-500 text-sm">
                                {t(
                                    'Please check your email for the access code and enter it below',
                                )}
                            </p>
                            <div className="flex flex-column gap-1">
                                <h5 className=" m-0 p-0 text-sm text-500">
                                    {t('Access Code')}{' '}
                                </h5>
                                <InputText
                                    className="border-round-sm"
                                    value={data?.accessCode ?? ''}
                                    onChange={e =>
                                        handleOnChange(
                                            'accessCode',
                                            e.target.value,
                                        )
                                    }
                                />
                            </div>

                            <div>
                                {!!shown && (
                                    <ReCAPTCHA
                                        id="recaptcha1"
                                        ref={captchaRef}
                                        hl={
                                            getLanguageCode() === 'gb'
                                                ? 'en'
                                                : getLanguageCode()
                                        }
                                        size="normal"
                                        sitekey="6LcyqHckAAAAALSdq50sK4puawkLWajIgAMiYyVt"
                                        onChange={() => {
                                            setDisableButton(false)
                                        }}
                                        onErrored={() => {
                                            if (
                                                !!window?.grecaptcha &&
                                                !!window?.grecaptcha?.reset
                                            ) {
                                                window?.grecaptcha?.reset()
                                            }

                                            setDisableButton(true)
                                        }}
                                        onExpired={() => {
                                            if (
                                                !!window?.grecaptcha &&
                                                !!window?.grecaptcha?.reset
                                            ) {
                                                window?.grecaptcha?.reset()
                                            }

                                            setDisableButton(true)
                                        }}
                                    />
                                )}
                            </div>
                            <Divider className="m-1" />
                            <Button
                                disabled={!!disableButton}
                                className="border-round-sm primary-button h-3rem"
                                icon="pi pi-send"
                                label={t('SUBMIT')}
                                onClick={() => handleSubmitAccessCode()}
                            />
                        </div>
                    </div>
                </div>
            )}

            {stage === 'video' && (
                <div>
                    <div className="flex flex-column bg-white p-3">
                        <div className="flex flex-column gap-3 justify-content-center  align-items-center ">
                            <h1 className="m-0 p-0 main-text-color text-center">
                                {t('Tutorial Videos')}
                            </h1>
                            <div className="flex flex-column md:flex-row  gap-3 p-5">
                                {videoList.map((item, idx) => (
                                    <Card
                                        key={idx}
                                        title={item.title}
                                        subTitle={item.sub}
                                        className="md:w-25rem ">
                                        <div className="flex flex-column h-full">
                                            <div className="h-full ">
                                                <img
                                                    className="w-full h-full"
                                                    src={item.icon}
                                                />
                                            </div>
                                            <div
                                                className={`flex align-items-end flex-wrap h-full ${item.className}`}>
                                                <Divider />
                                                <Button
                                                    className="h-3rem w-full flex align-items-end"
                                                    label={t('WATCH')}
                                                    icon="pi pi-eye"
                                                    onClick={() => {
                                                        if (item.url) {
                                                            setUrl(item.url)
                                                            setDialog(true)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Dialog
                className="col-10"
                visible={dialog}
                onHide={() => setDialog(false)}>
                <div className="" style={{height: '80%'}}>
                    <iframe
                        className="w-full "
                        height="600"
                        src={url}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </Dialog>
            <Toast ref={toast} />
        </div>
    )
}

export default TutorialVideos
