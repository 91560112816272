export function getLanguageCode() {
  return localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
}

export function setLanguageCode(languageCode) {
  localStorage.setItem("i18nextLng", languageCode);
}

export function removeLanguageCode() {
  localStorage.removeItem("i18nextLng");
}

export function countryToLanguage(countryCode) {
  const mapping = {
    US: "en",
    GB: "gb",
    BG: "bg",
  };

  if (mapping.hasOwnProperty(countryCode)) {
    return mapping[countryCode];
  } else {
    return "en";
  }
}

export function languageToCountry(languageCode) {
  const mapping = {
    en: "US", // English to United States
    gb: "GB",
    bg: "BG", // Bulgarian to Bulgaria
  };

  // Check if the language code exists in the mapping
  if (mapping.hasOwnProperty(languageCode)) {
    return mapping[languageCode];
  } else {
    // Return a default or throw an error if the language code is not found
    return "US"; // Default country code
  }
}
