import './App.css'
import TutorialVideos from './components/TutorialVideos'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import './i18n/i18nConfig'
function App() {
    return (
        <div className="col-12 flex justify-content-center">
            <div className="w-full">
                <TutorialVideos />
            </div>
        </div>
    )
}

export default App
